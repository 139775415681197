/* Typography */
.h1 {
    @apply text-5xl font-extrabold;
    /* @apply text-5xl font-extralight; */
}

.h2 {
    @apply text-4xl font-extrabold;
}

.h3 {
    @apply text-3xl font-extrabold;
}

.h4 {
    @apply text-2xl font-extrabold;
}

@screen md {
    .h1 {
        @apply text-6xl;
    }

    .h2 {
        @apply text-5xl;
    }

    .h3 {
        @apply text-4xl;
    }
}


.new-button_button--primary {
   
    background: radial-gradient(231.94% 231.94% at 50% 100%, 
    hsl(var(--primary-400)) 0, hsl(var(--primary-900) / 0%) 25.24%), 
    linear-gradient(180deg, hsl(var(--contrast-foreground) / 0%), hsl(var(--contrast-foreground) / 4%)), hsl(var(--primary-900) / 1%);
    box-shadow: 0 0 0 0 hsl(var(--primary-100) / 40%) , 0 2px 5px 0 
    hsl(var(--primary-100) / 39%), 0 8px 8px 0 hsl(var(--primary-100) / 34%), 0 19px 11px 0 
    hsl(var(--primary-100) / 20%), 0 34px 14px 0 hsl(var(--primary-100) / 6%), 0 53px 15px 
    0 hsl(var(--primary-100) / 1%), inset 0 0 12px 0 hsl(var(--contrast-foreground) / 8%), inset 0 -8px 32px 0 hsl(var(--primary-50));
    padding: 8px 17px;
    border-radius: 10px;
    position: relative;
    color: hsl(var(--accent-foreground));  
    text-align: center;
    line-height: 20px;
    font-weight: 500;
    z-index: 1;
   
}
.btn,
.btn-sm {
    @apply text-sm font-medium inline-flex items-center justify-center border border-transparent rounded-full whitespace-nowrap transition duration-150 ease-in-out;
}

.btn {
    @apply px-4 py-1.5;
}

.btn-sm {
    @apply px-3 py-1;
}

